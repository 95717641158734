import { render, staticRenderFns } from "./lista-balancos.vue?vue&type=template&id=0f9cdd52&scoped=true&"
import script from "./lista-balancos.vue?vue&type=script&lang=js&"
export * from "./lista-balancos.vue?vue&type=script&lang=js&"
import style0 from "./lista-balancos.vue?vue&type=style&index=0&id=0f9cdd52&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f9cdd52",
  null
  
)

export default component.exports